import { useState } from 'react'
import { notification } from 'antd'
import axios from 'redaxios'
import { API_URL } from '../common/utils'
// import {API_URL} from '../common/utils'


export const useContactUs = (onSuccess: Function) => {
  const [loading, setLoading] = useState(false)
  const sendData = async (values: any) => {
    setLoading(true)
    try {
      await axios({
        method: 'POST',
        url: `${API_URL}/v1/entity/apply-loan`,
        data: {
          loanType: values.loanType,
          fullName: values.fullName,
          contactNumber: values.contactNumber,
          email: values.emailid,
          comment: values.message,
          source: `${values.loanType ? `Home Page` : `Apply Page`}`
        },
      })
      setLoading(false)
      onSuccess()
      notification.success({
        message: 'Success!',
        description:
          'Your request has been received. Our customer success team will get in touch with you soon!',
      })
    } catch (e) {
      setLoading(false)
    }
  }
  return {
    loading,
    sendData,
  }
}
